import Navbar from './components/Navbar';
// import About from './components/About';
import ContactForm from './components/Contact';
import { Box, Flex } from '@chakra-ui/react';
import Footer from './components/Footer';

const App = () => {
  return (
    <Flex direction="column" minH="100vh">
      <Navbar/>

      <Flex direction='column' flex='1' alignItems="center" justifyContent="center" w="100%" >
        <Box pb={10}>
          <ContactForm />
        </Box>
      </Flex>
      <Footer />
    </Flex>
  );
};

export default App;
