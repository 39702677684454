export interface StageConfig {
    backendAPI: APIConfig;
    stage: Stage;
}

export enum Stage {
    PROD = "Prod",
    BETA = "Beta",
}

export const PROD_STAGE_CONFIG: StageConfig = {
    stage: Stage.PROD,
    backendAPI: {
        baseURL: "https://oknidp4f2g.execute-api.us-east-1.amazonaws.com/prod/",
        eventRequestPath: "eventRequest"
    },
}

export interface APIConfig {
    baseURL: string;
    eventRequestPath: string;
}