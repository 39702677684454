import React, { useState } from 'react';
import { Box, Button, FormControl, FormLabel, Input, Textarea, VStack, Text, useToast, Grid, GridItem, Image, Flex } from '@chakra-ui/react';

import cart1 from '../assets/cart1.jpg';
import { sendEventRequest } from '../utils/BackendService';

const ContactForm = () => {
  const toast = useToast();

  const [formData, setFormData] = useState({
    name: '',
    email: '',
    phoneNumber: '',
    eventDate: '',
    location: '',
    guestCount: '',
    notes: '',
  });

  const [submitting, setSubmitting] = useState(false);
  const [submitted, setSubmitted] = useState(false);


  const handleChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    setSubmitting(true);

    const requestSent = await sendEventRequest(
      {
        name: formData.name,
        fromEmail: formData.email,
        eventDate: formData.eventDate,
        location: formData.location,
        guestCount: formData.guestCount,
        phoneNumber: formData.phoneNumber,
        notes: formData.notes,
      }
    );

    if (requestSent) {
      setSubmitted(true);
      setFormData({
        name: '',
        email: '',
        phoneNumber: '',
        eventDate: '',
        location: '',
        guestCount: '',
        notes: '',
      });
    } else {
      toast({
        title: "Request inquiry failed.",
        description: "Please try again. If error persists please email us directly at \"events@finepourcoffee.com\". We apologize for any inconvenience",
        status: "error",
        duration: 3000,
        isClosable: true,
        position: "top",
      });
    }
    setSubmitting(false);
  };

  return ( <>
    { submitted && 

        <Flex
          id="submitted"
          p={8}
          borderRadius="md"
          boxShadow="md"
          maxW="800px"
          w="100%"
          mx="auto"
          alignItems={"center"}
          flexDirection={"column"}
        >
          <Text
            fontSize="6xl"
            fontWeight="bold"
            color={"green.400"}
            mb={6}
            textAlign="center"
            fontFamily="'Marck Script', cursive"
          >
            Thank You!
          </Text>

          <Text
            fontSize="2xl"
            mb={20}
            textAlign="center"
          >
            We've received your event inquiry and will be in touch shortly with more details!
          </Text>

          <Button
                borderRadius="20px"
                border="1px"
                variant="outline"
                width="30%"
                onClick={() => setSubmitted(false)}
              >
                Go Back
          </Button>

          </Flex>
     
    } 


  
    { !submitted && <>
    <Grid
      templateColumns={{ base: "1fr", md: "minmax(600px, 1fr) 0.8fr" }}
      templateAreas={{
        base: `"image" "form"`,
        md: `"form image"`,
      }}
      gap={4}
    >

        <GridItem area="image" display="flex" justifyContent="center" alignItems="center">
          <Image
            src={cart1}
            alt="Coffee Cart"
            maxW="400px"
            h="auto"
            objectFit="contain"
            borderRadius="25px"
          />
        </GridItem>
        <GridItem area="form">
          <Box
            id="contact"
            p={8}
            borderRadius="md"
            boxShadow="md"
            maxW="600px"
            w="100%"
            mt={{ base: 0, md: 10 }}
            mx="auto"
          >
            <Text
              fontSize="3xl"
              fontWeight="bold"
              mb={6}
              textAlign="center"
              fontFamily="'Marck Script', cursive"
            >
              Inquiry
            </Text>
            <form onSubmit={handleSubmit}>
              <VStack spacing={4}>
                <FormControl isRequired>
                  <FormLabel>Name / Business</FormLabel>
                  <Input
                    type="text"
                    placeholder="Your Name"
                    name="name"
                    value={formData.name}
                    onChange={handleChange}
                  />
                </FormControl>
                <FormControl isRequired>
                  <FormLabel>Email</FormLabel>
                  <Input
                    type="email"
                    placeholder="Your Email Address"
                    name="email"
                    value={formData.email}
                    onChange={handleChange}
                  />
                </FormControl>
                <FormControl>
                  <FormLabel>Phone Number (optional)</FormLabel>
                  <Input
                    type="tel"
                    placeholder="Your Phone Number"
                    name="phoneNumber"
                    value={formData.phoneNumber}
                    onChange={handleChange}
                  />
                </FormControl>
                <FormControl isRequired>
                  <FormLabel>Event Date</FormLabel>
                  <Input
                    type="date"
                    name="eventDate"
                    value={formData.eventDate}
                    onChange={handleChange}
                  />
                </FormControl>
                <FormControl isRequired>
                  <FormLabel>Location</FormLabel>
                  <Input
                    type="text"
                    placeholder="Venue name or address"
                    name="location"
                    value={formData.location}
                    onChange={handleChange}
                  />
                </FormControl>
                <FormControl isRequired>
                  <FormLabel>Estimated guest count</FormLabel>
                  <Input
                    type="text"
                    name="guestCount"
                    value={formData.guestCount}
                    onChange={handleChange}
                  />
                </FormControl>
                <FormControl>
                  <FormLabel>Special Requests / Questions</FormLabel>
                  <Textarea
                    placeholder="Any additional request?"
                    rows={3}
                    name="notes"
                    value={formData.notes}
                    onChange={handleChange}
                  />
                </FormControl>
                <Button
                  borderRadius="20px"
                  border="1px"
                  variant="outline"
                  width="50%"
                  isLoading={submitting}
                  type="submit"
                  loadingText="Submitting"
                >
                  Submit
                </Button>
              </VStack>
            </form>
          </Box>
        </GridItem>
    </Grid></>
    } </>
  );
};

export default ContactForm;
