import { PROD_STAGE_CONFIG } from "../config/StageConfig"

const backendAPI = PROD_STAGE_CONFIG.backendAPI;

export const sendEventRequest = async (body: any) => {

    try {
        const response = await fetch(backendAPI.baseURL+backendAPI.eventRequestPath, {
            body: JSON.stringify(body),
            headers: {
                'Content-Type': 'application/json',

            },
            method: 'POST'            
        });

        return response.ok;

    } catch(error) {
        return false;
    }
}