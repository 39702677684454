import { Flex, Text, Link, Image } from '@chakra-ui/react';
import instagram from '../assets/instagram.svg';
import facebook from '../assets/facebook.svg';

const Footer = () => {
  return (
    <Flex
      bg="blackAlpha.100"
      color="black"
      p={6}
      align="center"
      justify="center"
      direction="column"
    >
      {/* Social Media Links */}
      <Flex gap={6} mb={8} mt={8}>
        <Link
          href="https://www.instagram.com/finepourcoffee"
          isExternal
          _hover={{ transform: 'scale(1.1)', transition: 'transform 0.2s' }}
        >
          <Image src={instagram} alt="Instagram Logo" height="25px" />
        </Link>
        <Link
          href="https://www.facebook.com/finepourcoffee"
          isExternal
          _hover={{ transform: 'scale(1.1)', transition: 'transform 0.2s' }}
        >
          <Image src={facebook} alt="Facebook Logo" height="25px" />
        </Link>
      </Flex>

      {/* Copyright Text */}
      <Text fontSize="sm" textAlign="center">
        © 2024 Finepour Coffee
      </Text>
    </Flex>
  );
};

export default Footer;
