import logo from '../assets/logo.png';
import instagram from '../assets/instagram.svg'
 
import {
  Flex,
  Spacer,
  Link,
  IconButton,
  Drawer,
  DrawerBody,
  DrawerHeader,
  DrawerOverlay,
  DrawerContent,
  useDisclosure,
  Image,
  Box,
  useBreakpointValue,
} from '@chakra-ui/react';
import { HamburgerIcon, CloseIcon } from '@chakra-ui/icons';

const Navbar = () => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const isDesktop = useBreakpointValue({ base: false, md: true });

  return (
    <Flex
      bg="white"
      color="black"
      p={4}
      align="center"
      justify={{ base: "center", md: "space-between" }} // Center logo on small screens
    >
      {/* Logo */}
      <Image
        src={logo}
        alt="FinePourCoffee Logo"
        height="60px"
        className="d-inline-block align-top"
        ml={{ base: 0, md: 20 }} // No left margin on small screens
      />

      {isDesktop ? (
        <>
          <Spacer />
          {/* Links for desktop */}
          <Flex gap={4}>
            {/* <Link href="#about" _hover={{ textDecoration: 'none', color: 'yellow.400' }}>About</Link> */}
            {/* <Link href="#menu" _hover={{ textDecoration: 'none', color: 'yellow.400' }}>Menu</Link> */}
            <Link _hover={{ textDecoration: 'none', color: 'yellow.400' }}>Contact</Link>

            {/* Instagram Icon */}
            <Link
              href="https://www.instagram.com/finepourcoffee"
              isExternal
              ml={4}
            >
              <Image src={instagram} alt="Instagram Logo" height="25px" />
            </Link>
          </Flex>
        </>
      ) : (
        // Hamburger icon for mobile
        <IconButton
          icon={<HamburgerIcon />}
          aria-label="Open Menu"
          onClick={onOpen}
          variant="ghost"
          size="lg"
          position="absolute" // Position hamburger icon
          right="4" // Align to the right on small screens
        />
      )}

      {/* Drawer for mobile menu */}
      <Drawer isOpen={isOpen} placement="right" onClose={onClose}>
        <DrawerOverlay />
        <DrawerContent>
          <DrawerHeader>
            <Flex justify="space-between" align="center">
              <Box>Menu</Box>
              <IconButton
                icon={<CloseIcon />}
                aria-label="Close Menu"
                onClick={onClose}
                variant="ghost"
                size="sm"
              />
            </Flex>
          </DrawerHeader>
          <DrawerBody>
            <Flex direction="column" gap={4}>
              <Link href="#about" onClick={onClose}>About</Link>
              <Link href="#menu" onClick={onClose}>Menu</Link>
              <Link href="#contact" onClick={onClose}>Contact</Link>
              <Link href="https://www.instagram.com/finepourcoffee" isExternal>
                <Image src={instagram} alt="Instagram Logo" height="25px" />
              </Link>
            </Flex>
          </DrawerBody>
        </DrawerContent>
      </Drawer>
    </Flex>
  );
};

export default Navbar;
